/* =========== Footer Section ======= */
.footer {
  background-image: linear-gradient(
    120deg,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0.7) 100%
  ),
  url("../img/baanner/2.jpg");
  color:var(---lightColor);
  background-color: #242020;
  padding-top: 80px;
}
.footer-logo {
  width: 150px;
  height: 150px;
  margin: auto;
}
.footer-logo img {
  width: 100%;
  height: 100%;
  transform: scale(1);
}
.footer-desc p {
  color: var(---lightColor);
}

.footer-head {
  padding-bottom:10px;
  position: relative;
}
.footer-head h4 {
  font-family: var(---HeadFont);
  color:var(---primaryColor);
  font-size: 20px;
  padding-bottom: 0px;
  font-weight: 600;
}

.footer-link ul {
  padding: 0px;
}
.footer-link ul li{
  line-height: 26px;
}

.footer-link ul li a {
  padding: 0px;
  color: var(---lightColor);
}

.footer-address ul li i{
  padding: 0px;
  color:var(---primaryColor);
  font-size:22px;
  margin-right:7px;
}
.footer-address ul{
  padding: 0px;
}

.footer-address ul li span a {
  padding: 0px;
  color: var(---lightColor);
}
/* =============== Footer CopyRight ============= */
.Footer_copyRight {
  background-color:var(---darkColor);
}

.Footer_copyRight .copyright {
  text-align: left;
  padding: 20px 0px 20px 0px;
}
.Footer_copyRight .copyright p {
  font-family: var(---SubHeading);
  color: var(---lightColor);
  margin-bottom: 0px;
}
.Footer_copyRight .copyright p span{
  color:var(---primaryColor);
}

.Footer_copyRight .develope-by {
  text-align: right;
  padding: 20px 0px 20px 0px;
}
.Footer_copyRight .develope-by p {
  font-family: var(---SubHeading);
  text-align: right;
  color: var(---lightColor);
  margin-bottom: 0px;
}
.Footer_copyRight .develope-by p span{
  color:var(---primaryColor);
}
.Footer_copyRight .develope-by p a {
  text-decoration: none;
  color: var(---lightColor);
}

@media (max-width: 990px) {
  .footer {
    padding: 20px 0px;
    height: auto;
  }
  .footer-logo img {
    object-fit: contain;
  }

  .footerUseful_link {
    margin-top: 20px;
  }
  .footerProperty_Types {
    margin-top: 20px;
  }
  .footerProperties_Available {
    margin-top: 20px;
  }
  .Footer-MainSection-col-2 {
    margin-top: 20px;
  }
  .Footer-icons {
    margin: 0px 5px 0px 0px;
  }
  .Footer_copyRight .develope-by {
    text-align:center;
  }
  .Footer_copyRight .develope-by p {
    text-align:center;
  }
  .Footer_copyRight .copyright {
    padding: 0px 10px 0px 10px;
  }
  .Footer_copyRight .develope-by {
    text-align: right;
    padding: 0px;
  }
}
