.Amenities-banner {
    background-image: linear-gradient(
        120deg,
        rgba(0, 0, 0, 0.5) 0%,
        rgba(0, 0, 0, 0.5) 100%
      ),
      url("../img/baanner/gallery-bg.jpg");
    height: 70vh;
    background-size: 100% 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .AmenitiesBanner_Txt{
   margin-top: 100px;
  }
  
  .AmenitiesBanner_Txt h2{
    font-size: 50px;
    font-family: var(---HeadFont);
    color: var(---lightColor);
    text-align: center;
  }

  
  @media (max-width: 990px) {
    .AmenitiesBanner_Txt{
      margin-top: 70px;
    }
    .AmenitiesBanner_Txt h2{
      font-size: 24px;
      font-family: var(---HeadFont);
      color: var(---lightColor);
      text-align: center;
    }
  }
  @media (max-width:768px) {
    .Amenities-banner {
      height: 50vh;
    }
  }


/* ======= Aminities pages ======= */
.pricing-card {
  background-color: #2b2b2b;
  margin-bottom: 30px;
}
.amenities-img{
  width: 100%;
  height: 100%;
}
.amenities-img img{
  width: 100%;
  height: 100%;
}
.pricing-card .desc {
  padding: 20px;
} 
.pricing-card .name {
  line-height: 1;
  margin: 0;
  font-family:var(---HeadFont);
  font-size: 20px;
  color:var(---lightColor);
  margin-bottom: 20px;
}


/* =========== Aminities Section ========== */

.services-section {
	padding-bottom: 0;
	padding-top: 80px;
  
}
.services-item {
  background-color: #2b2b2b;
  margin-bottom: 30px;
  padding: 10px;
}

.services-item .si-pic {
	height: 293px;
	position: relative;
	background-size: cover;
  background-position: center center;
}
.services-item .si-pic img{
	height: 100%;
    width:100%;
	
}
.services-item .si-pic .service-icon {
	width: 75px;
	height: 75px;
	background:rgba(0, 0, 0, 0.7);
	border: 1px solid var(---primaryColor);
	text-align: center;
	line-height: 89px;
	border-radius: 50%;
	position: absolute;
	bottom: -35px;
	display:flex;
	justify-content: center;
	align-items: center;
	padding:17px;
	left: 50%;
	-webkit-transform: translateX(-44.5px);
	transform: translateX(-44.5px);
}
.service-icon img {
	width:100%;
	height:100%;
	
}

.services-item .si-text {
	text-align: center;
	padding-top:50px;
}

.services-item .si-text h3 {
	color:var(---lightColor);
  font-size:20px;
	margin-bottom:10px;
}
.services-item .si-text p{
    font-size:16px;
}

@media(max-width:1250px)
{
	.services-item .si-pic {
		height: 293px;
		position: relative;
		background-size: cover;
			background-position: center center;
	}
	.services-item .si-pic img{
		height: 100%;
		width:100%;
		
	}
}
@media(max-width:990px)
{
	.services-item .si-pic {
		height: 293px;
		position: relative;
		background-size: cover;
		background-position: center center;
	}
	.services-item .si-pic img{
		height: 100%;
		width:100%;
		
	}
}

@media(max-width:990px)
{
	.services-section {
		padding-bottom: 0;
		padding-top: 50px;
	}
}
@media(max-width:750px)
{
	.services-section {
		padding-bottom: 0;
		padding-top: 30px;
	}
}
@media(max-width:500px)
{
	.services-section {
		padding-bottom: 0;
		padding-top: 20px;
	}
  .services-item .si-pic {
		height: 293px;
		position: relative;
		background-size: cover;
			background-position: center center;
	}
	.services-item .si-pic img{
		height: 100%;
		width:100%;
		
	}
}
