.Gallery-banner {
    background-image: linear-gradient(
        120deg,
        rgba(0, 0, 0, 0.2) 0%,
        rgba(0, 0, 0, 0.2) 100%
      ),
      url("../img/baanner/gallery-bg.jpg");
    height: 70vh;
    background-size:cover;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .GalleryBanner-Txt{
    margin-top: 100px;
  }
  
  .GalleryBanner-Txt h2{
    font-size: 50px;
    font-family: var(---HeadFont);
    color: var(---lightColor);
    text-align: center;
  }
  .GalleryBanner-Txt p{
    text-align: center;
    color: var(---lightColor);
    font-size: 24px;
  }
  
  @media (max-width: 990px) {
    .GalleryBanner_Txt{
      margin-top: 70px;
    }
    .GalleryBanner-Txt h2{
      font-size: 24px;
      font-family: var(---HeadFont);
      color: var(---lightColor);
      text-align: center;
    }
    .GalleryBanner-Txt p{
      text-align: center;
      color: var(---lightColor);
      font-size: 16px;
    }
  }

  @media (max-width:768px) {
    .Gallery-banner {
      height: 50vh;
    }
  }


  /* ============ Gallery Items Pages ======== */
  .home_gallery{
    width: 100%;
    height: 100%;
    max-height: 200px;
  }
  
  .home_gallery img{
    width: 100%;
    height: 100%;
  }
  