.Room-banner {
  background-image: linear-gradient(
      120deg,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0.2) 100%
    ),
    url("../img/rooms/deluxeroom/deluxe1.jpg");
  height: 70vh;
  background-size: 100% 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  /* animation: shrink 4s infinite alternate ease-in-out; */
}
.RoomBanner_Txt{
  margin-top: 100px;
}

.RoomBanner_Txt h2{
  font-size: 50px;
  font-family: var(---HeadFont);
  color: var(---lightColor);
  text-align: center;
}
.RoomBanner_Txt p{
  text-align: center;
  color: var(---lightColor);
  font-size: 24px;
}

@media (max-width: 990px) {
  .RoomBanner_Txt{
    margin-top: 70px;
  }
  .RoomBanner_Txt h2{
    font-size: 24px;
    font-family: var(---HeadFont);
    color: var(---lightColor);
    text-align: center;
  }
  .RoomBanner_Txt p{
    color: var(---lightColor);
    padding: 0px 10px 0px 10px;
    font-size: 16px;
  }
}
@media (max-width:768px) {
  .Room-banner {
    height: 50vh;
  }
}

/* ========== Room Page ===== */
.room-section .room-img{
  width: 100%;
  height: 100%;
}
.room-section .room-img img{
  width: 100%;
  height: 100%;
}
.room-details{
  padding: 20px 30px;
}
.room-section .icon-list {
  font-size: 18px;
  line-height: 35px;
  color: #797979;
  font-weight: 400;
  display: flex;
}

.room-section .icon-list ul {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
  padding: 0px;
}

.room-section .icon-list ul li {
  margin-right: 30px;
}

.room-section .icon-list ul li i {
  margin-right: 10px;
  position: relative;
  font-size: 24px;
  vertical-align: middle;
}
.room-section .link-btn {
  margin-bottom: 30px;
}
.btn-style-one.btn-md {
  padding: 12px 25px;
  font-size: 13px;
  letter-spacing: 2px;
  color: #ffffff;
  font-weight: 600;
}
.btn-style-one {
  position: relative;
  display: inline-block;
  padding: 15px 30px;
  overflow: hidden;
  text-transform: uppercase;
  vertical-align: middle;
  border-radius: 3px;
  transition: .5s;
  z-index: 0;
  background: var(---primaryColor);
  font-size: 14px;
  letter-spacing: 2px;
  line-height: 26px;
  color: #ffffff;
  font-weight: 700;
  font-family: "Roboto";
}
.theme-btn {
  display: inline-block;
  transition: all .5s ease;
  -moz-transition: all .5s ease;
  -webkit-transition: all .5s ease;
  -ms-transition: all .5s ease;
  -o-transition: all .5s ease;
}
.btn-style-one:hover {
  color: #fff;
}
.btn-style-one:hover:before {
  width: 100%;
  border-radius: 0;
  opacity: 1;
  border: 1px solid var(---primaryColor);
}
.btn-style-one:before {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 30px;
  content: "";
  background-color: #222;
  transition: .5s;
  opacity: 0;
  border-top-right-radius: 33px;
  border-bottom-right-radius: 33px;
  z-index: -1;
  border-top-left-radius: 33px;
}