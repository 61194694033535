/* ======= my css ===== */

/* Floating Social Media Icons */
.rightIcon {
  position: fixed;
  top: 50%;
  left:20px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translate(-50%,-50%);
  z-index: 99;
}
.rightIcon-list {
  display: block;
}
.rightIcon-list li {
  display: block;
  border-radius: 18px;
  width: 36px;
  height: 36px;
  margin-bottom: 10px;
}
.rightIcon-list li:after {
  content: "";
  display: block;
  margin: 0 auto;
  width: 1px;
  height: 11px;
  background:var(---primaryColor);
}
.rightIcon-list li:nth-child(1) {
  display: block;
  margin-bottom: 45px;
}
.rightIcon-list li:nth-child(1):before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}
.rightIcon-list li:nth-child(1):after {
  height: 15px;
}
.rightIcon-list li:nth-last-child(1):after {
  display: none;
}
.rightIcon-list li > em,
.rightIcon-list li > a {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  font-size: 20px;
  text-align: center;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.rightIcon-list li > em,
.rightIcon-list li > a .facebook {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  font-size: 20px;
  text-align: center;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  background: var(---lightColor);
  color: #3b5998;
}

.rightIcon-list li > em,
.rightIcon-list li > a .whatsapp {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  font-size: 20px;
  text-align: center;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  background: var(---lightColor);
  color: #47c757;
}

.rightIcon-list li > em,
.rightIcon-list li > a .instagram {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  font-size: 20px;
  text-align: center;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  background: var(---lightColor);
  color:#d92e7f
}


.rightIcon-list li > em,
.rightIcon-list li > a .calling {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border: 1px solid var(---primaryColor);
  font-size: 20px;
  text-align: center;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  background:var(---lightColor);
  /* background-color: #04a9d3; */
  color:#04a9d3;
}

.rightIcon-list li > em,
.rightIcon-list li > a .facebook:hover {
  background-color: transparent;
  color: #3b5998;
  transform: scale(1.4);
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  border: none;
}

.rightIcon-list li > em,
.rightIcon-list li > a .instagram:hover {
  background: none;
  color: #d92e7f;
  transform: scale(1.4);
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  border: none;
}


.rightIcon-list li > em,
.rightIcon-list li > a .whatsapp:hover {
  background-color: transparent;
  color: #47c757;
  transform: scale(1.4);
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  border: none;
}

.rightIcon-list li > em,
.rightIcon-list li > a .calling:hover {
  background-color: transparent;
  color: #04a9d3;
  transform: scale(1.4);
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  border: none;
}

@media (max-width: 600px) {
  .rightIcon {
    position: fixed;
    top: 75%;
    bottom: 0%;
    right: 15px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(50%);
    z-index: 99;
  }
  .rightIcon-wrap ul {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  .rightIcon-wrap ul li {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-right: 20px;
  }
  .rightIcon-list li:nth-child(1):after {
    height: 0px;
  }
  .rightIcon-list li:nth-child(1) {
    display: block;
    margin-bottom: 30px;
  }
}


/* ========== Other Icon in responsive ==== */
.Right_Icon{
  position: fixed;
  left:20px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 99;
  bottom: 0;
  width: 35px;
  height: 35px;
  z-index: 999;
}

.Right_Icon ul{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 35px;
  height: 35px;
  z-index: 999;
}
.Right_Icon ul li{
  list-style: none;
}
.Right_Icon ul li a{
  position: absolute;
  left: 0;
  display: inline-block;
  color: var(---lightColor);
  text-decoration: none;
  width: 35px; 
  height: 35px;
  text-align: center;
  line-height:40px;
  font-size: 20px;
  border-radius: 50px;
  transition: 1s;
  transition-delay: 0s;
}
.Right_Icon ul::before{
  content: '\f1e0';
  font-family: 'fontAwesome';
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(---primaryColor);
  color: var(---lightColor);
  z-index: 10;
  width: 100%;
  height:35px;
  text-align: center;   
  line-height:40px;
  font-size:18px;
  border-radius: 50px;
  transition: 1s;
  transition-delay: .5s;
}
.Right_Icon ul:hover li a{
  transform: rotate(-360deg);
  transition-delay: .5s;
}
.Right_Icon ul:hover::before{
  transition-delay: 0s;
  width:35px;
}
.Right_Icon ul li:nth-child(1) a{
  /* background-color: #3b5998; */
  background: #d5d5d5;
  color: #3b5998;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
}
.Right_Icon ul:hover li:nth-child(1) a{
 left: calc((40px + 5px) * 1);
}
.Right_Icon ul li:nth-child(2) a{
  /* background: linear-gradient(45deg,#f09433,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888); */
  background: #d5d5d5;
  color: #d92e7f;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
}
.Right_Icon ul:hover li:nth-child(2) a{
 left: calc((40px + 5px) * 2);
}
.Right_Icon ul li:nth-child(3) a{
  /* background-color: #47c757; */
  background: #d5d5d5;
  color: #47c757;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
}
.Right_Icon ul:hover li:nth-child(3) a{
 left: calc((40px + 5px) * 3);
}
.Right_Icon ul li:nth-child(4) a{
  /* background: #00AFF0; */
  background: #d5d5d5;
  color: #00AFF0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
}
.Right_Iconul:hover li:nth-child(4) a{
 left: calc((25px + 5px) * 4);
}
