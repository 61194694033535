.header{
  background-color: var(---primaryColor);
  padding: 3px 0px 0px 0px;
  height: 30px ;
}
.headerlink ul {
  padding: 0px;
}

.headerlink ul li a {
  padding: 0px;
  color: var(---lightColor);
}

.headerlink ul {
  padding: 0px;
}

.headerlink ul li a {
  padding: 0px;
  color: var(---lightColor);
}
.headerlink ul li i{
  padding: 0px;
  color:var(---lightColor);
  font-size:22px;
  margin-right:7px;
}


@media (max-width: 992px) {
  .header{
    display: none;
  }
}
/* ========= NavBar Section ========== */
.nav-bar-component {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
  height: fit-content;
  z-index: 10000 !important;
}

.NavBar {
  background: rgb(0,0,0);
  z-index: 99;
}

.NavBar-bg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: rgb(0,0,0);
}

.navbar-nav a {
  padding-left: 12px;
  padding-right: 12px;
  margin-right: 10px;
  text-decoration: none;
  color: var(---lightColor);
  text-transform: uppercase;
  /* font-weight: 600; */
  font-family: var(---HeadFont);
}

.navbar-dark .navbar-nav .nav-link {
  text-align: left;
}
.navbar #collasible-nav-dropdown {
  padding: 0;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 500;
  color: var(---primaryColor);
}
.navbar-dark .navbar-nav .nav-link{
  margin-top: -7px;
  color: var(---primaryColor);
}
.navbar-dark .navbar-nav .nav-link:hover{
  color: var(---primaryColor);
}
.navbar-nav .show>.nav-link {
  color: var(---primaryColor);
}

.NavLogo {
  height: 100px;
  width: 100px;
  transform: scale(1.4);
}

.NavLogo img {
  height: 100%;
  width: 100%;
}
.nav-link a:hover {
  color:var(---primaryColor);
}

@media (max-width: 992px) {
  .NavBar {
    background:var(---darkColor);
    z-index: 99;
  }
  .Social-media-icons {
    display: none;
  }
  .navbar-nav {
    text-align: left;
  }
  .navbar-nav a {
    margin: 15px 0px 15px 0px !important;
  }
  .navbar-toggler {
    background-color: var(---primaryColor);
  }
  .navbar-dark .navbar-nav .nav-link{
    margin-top: 0px;
  }
  .navbar-Btn{
    display: none;
  } 
}





