.Contact-banner {
  background-image: linear-gradient(
      120deg,
      rgba(0, 0, 0, 0.3) 0%,
      rgba(0, 0, 0, 0.3) 100%
    ),
    url("../img/baanner/contact-bg.JPG");
  height: 70vh;
  background-size: 100% 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ContactBanner-Txt {
  margin-top: 100px;
}

.ContactBanner-Txt h2{
  font-size: 50px;
  font-family: var(---HeadFont);
  color:var(---lightColor);
  text-align: center;
}

@media (max-width: 990px) {
  .ContactBanner-Txt h2{
    font-size: 24px;
    font-family: var(---HeadFont);
    color:var(---lightColor);
    text-align: center;
  }
  .ContactBanner-Txt p {
    text-align: center;
    color:var(---lightColor);
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .Contact-banner {
    height: 50vh;
  }
}
/* ============ Contact Section ============ */
.media-contact {
  box-shadow: 0 0 1px grey;
  padding: 15px;
  border-radius: 5px;
}
.contact-icon {
  background-color: var(---primaryColor);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contact-icon i {
  font-size: 18px;
  color:var(---lightColor);
}
.contact-desc h4 {
  font-family: var(---HeadFont);
  font-size: 20px;
  font-weight: 600;
  color: var(---darkColor);
}
.contact-desc a {
  font-family: var(---HeadFont);
  color: var(---darkColor);
}

/* ========== contact-map =========== */
.contact-map {
  width: 100%;
  height: 500px;
}

@media (max-width: 990px) {
  .contact-map {
    width: 100%;
    height: 350px;
  }
}

@media (max-width: 750px) {
  .contact-map {
    width: 100%;
    height: auto;
  }
}

/* ============== Contact Page ====== */
.Contact-section {
  position: relative;
  padding: 110px 0 107px;
  margin-top:50px;
}
.Contact-section:before {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  max-width: 1200px;
  height: 100%;
  background-image: url(../img/bg-booking.jpg);
  background-repeat: no-repeat;
}
.contact-column .left {
  padding: 60px 70px 70px;
  position: relative;
  z-index: 9;
  margin-top: -150px;
}
.contact-column .left:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #f8f5f0;
  z-index: 9;
  height: 100%;
}
.contact-column .left .contact-form {
  position: relative;
  z-index: 9;
}
.contact-column .left .left-Txt {
  margin-bottom: 54px;
  position: relative;
  z-index: 9;
}
.contact-column .left .left-title:before {
  content: "";
  position: absolute;
  top: -60px;
  left: 0;
  width: 95px;
  height: 13px;
  background: var(---primaryColor);
}
.left-Txt .left-title {
  position: relative;
  font-size: 18px;
  color: gray;
  text-transform: uppercase;
  display: inline-block;
  margin-bottom: 14px;
  font-family: var(---HeadFont);
  letter-spacing: 4px;
}
.left-Txt h2 {
  color: var(---primaryColor);
  font-family: var(---HeadFont);
}
.contact-content .right {
  padding-left: 70px;
  position: relative;
}
.right-Txt h2 {
  color:var(---lightColor);
  font-family: var(---HeadFont);
}
.right .form-label {
  color:var(---lightColor);
}
.right .form-control {
  border-radius: 0px;
}
.contact-form button{
  font-size: 20px;
  color:var(---lightColor);
  background-color: var(---primaryColor);
  padding: 5px 12px;
  border: none;
  color:var(---lightColor);
  padding: 7px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-family: var(---HeadFont);
}
.contact-form label{
  color:var(---lightColor);
}

@media (max-width: 992px) {
  .Contact-section:before {
    height:auto;
  }
  .Contact-section {
    position: relative;
    padding: 50px;
    margin-top: 50px;
  }
  .contact-column .left {
    padding: 50px;
    position: relative;
    z-index: 9;
    margin: 0px;
  }
  .contact-column .left .left-title:before {
    top: -50px;
  }
  .contact-content .right {
    margin-top: 50px;
    padding-left: 0px;
  }
}

@media (max-width: 768px) {
  .Contact-section {
    position: relative;
    padding: 10px;
    margin-top: 50px;
  }
  .contact-column .left {
    padding: 10px;
    position: relative;
    z-index: 9;
    margin: 0px;
}
.contact-column .left .left-title:before {
  top: -20px;
}
.left-Txt .left-title {
  position: relative;
  font-size: 18px;
  color: gray;
  text-transform: uppercase;
  display: inline-block;
  margin-top: 10px;
  margin-bottom: 1px;
  font-family: var(---HeadFont);
  letter-spacing: 4px;
}
.contact-content .right {
  /* margin-top: 120px; */
  padding-left: 0px;
}
}

@media (max-width: 350px) {
  .Contact-section {
    position: relative;
    padding: 0px;
    margin-top: 50px;
  }
}
