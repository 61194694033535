@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&family=Noto+Serif+JP&display=swap');

.App {
  text-align: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

html,
body {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  background-color:#222;
  color: var(---lightColor);
  /* font-family: var(---HeadFont); */
}
a{
  text-decoration: none;
}
section {
  padding: 70px 0px;
}
h1,h2,h3,h4,h5,h6{
  font-family: var(---HeadFont);
}
p{
  color:rgba(255,255,255,0.5);
  font-family: var(---SubHeading);
  text-align: justify;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media (max-width: 990px) {
  section {
    padding: 25px 0px;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

ul li{
  text-decoration: none;
}
ul li{
  list-style: none;
  text-decoration: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

:root {
  ---HeadFont: 'Gilda Display', serif;
  ---SubHeading: "Lato", sans-serif;
  ---primaryColor:#b58f4c;
  ---secondColor:#257fcb;
  ---header: "Roboto", sans-serif;
  ---lightColor: #fff;
  ---darkColor: #111;
}
