/* ============ Common Css ============= */
.headTitle {
  padding-bottom: 30px;
  position: relative;
}
.headTitle h6 {
  font-size: 14px;
  color:var(---primaryColor);
  text-transform: uppercase;
  font-family: var(---HeadFont);
  text-align: center;
  letter-spacing: 3px;
}
.headTitle h2 {
  text-transform: capitalize;
  font-family: var(---HeadFont);
  font-size: 32px;
  text-align: center;
  padding-bottom: 10px;
  color: var(---primaryColor);
}

.headTitle h2:before {
  border-bottom: 2px solid var(---lightColor);
  content: "";
  font-family: var(---HeadFont);
  top: 70px;
  position: absolute;
  text-align: center;
  width: 100px;
  left: 50%;
  transform: translate(-50%, -0px);
}
.headTitle p {
  font-family: var(---SubHeading);
  margin-top: 20px;
  text-align: center;
  padding: 0px 80px;
  color:rgba(255,255,255,0.5);
}
.dark{
  color: var(---darkColor);
}
@media (max-width: 768px) {
  .headTitle h2 {
    font-size: 26px;
    margin-top: 20px;
  }
  .headTitle p {
    padding: 0px 0px;
    text-align: justify;
  }
}
@media (max-width: 475px) {
  .headTitle h2 {
    font-size: 26px;
    margin-top:10px;
  }
}
/* ============  Swiper Common Css ============= */
.swiper-button-prev {
  position: absolute;
  top: var(--swiper-navigation-top-offset, 50%);
  width: 20px !important;
  height: 50px !important;
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(---primaryColor);
}
.swiper-button-next {
  position: absolute;
  top: var(--swiper-navigation-top-offset, 50%);
  width: 20px !important;
  height: 50px !important;
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(---primaryColor);
}
.swiper-button-prev:after {
  font-family: swiper-icons;
  font-size: 16px !important;
  text-transform: none !important;
  letter-spacing: 0;
  font-variant: initial;
  line-height: 1;
  color: var(---lightColor)f !important;
}
.swiper-button-next:after {
  font-family: swiper-icons;
  font-size: 16px !important;
  text-transform: none !important;
  letter-spacing: 0;
  font-variant: initial;
  color: var(---lightColor) !important;
  line-height: 1;
}

@media (max-width: 980px) {
  .swiper-button-prev {
    position: absolute;
    top: var(--swiper-navigation-top-offset, 50%);
    width: 15px !important;
    height: 30px !important;
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(---primaryColor);
  }
  .swiper-button-next {
    position: absolute;
    top: var(--swiper-navigation-top-offset, 50%);
    width: 15px !important;
    height: 30px !important;
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(---primaryColor);
  }
  .swiper-button-prev:after {
    font-family: swiper-icons;
    font-size: 10px !important;
    text-transform: none !important;
    letter-spacing: 0;
    font-variant: initial;
    line-height: 1;
    color: var(---lightColor) !important;
  }
  .swiper-button-next:after {
    font-family: swiper-icons;
    font-size: 10px !important;
    text-transform: none !important;
    letter-spacing: 0;
    font-variant: initial;
    color: var(---lightColor) !important;
    line-height: 1;
  }
}

/* ============= Banner Section ========== */
.homeBanner_Txt {
  text-align: center;
  margin-top: 80px;
}
.homeBanner_Txt .banner-star{
  margin-bottom: 20px;
}

.homeBanner_Txt .banner-star ul {
  margin: 0px;
  padding: 0px;
}
.homeBanner_Txt .banner-star ul li {
  display: inline-block;
  margin: 2px -1px 0 1px;
}

.homeBanner_Txt .banner-star ul li i {
  color: var(---primaryColor);
  font-size: 1em;
  margin: 0 8px 0 0;
}
.homeBanner_Txt h6{
  text-align: center;
  font-size: 18px;
  font-family: var(---HeadFont);
  color: var(---lightColor);
  letter-spacing: 4px;
  margin-bottom: 20px;
}
.homeBanner_Txt h2 {
  text-align: center;
  font-size: 50px;
  font-family: var(---HeadFont);
  color: var(---lightColor);
  text-transform: uppercase;
}
.home_Banner1 {
  background-image: linear-gradient(
      120deg,
      rgba(0, 0, 0, 0.1) 0%,
      rgba(0, 0, 0, 0.1) 100%
    ),
    url("../img/gallery/slidernew.jpg");
  height: 100vh;
  background-size:cover;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: shrink 4s infinite alternate;
}

.home_Banner2 {
  background-image: linear-gradient(
      120deg,
      rgba(0, 0, 0, 0.1) 0%,
      rgba(0, 0, 0, 0.1) 100%
    ),
    url("../img/gallery/slidernew1.jpg");
  height: 100vh;
  background-size:cover;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: shrink 4s infinite alternate ease-in-out;
}
.home_Banner3 {
  background-image: linear-gradient(
      120deg,
      rgba(0, 0, 0, 0.1) 0%,
      rgba(0, 0, 0, 0.1) 100%
    ),
    url("../img/gallery/slidernew.jpg");
  height: 100vh;
  background-size: cover;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: shrink 4s infinite alternate;
}

@keyframes shrink {
  0% {
    background-size: 115% 115%;
  }
  100% {
    background-size: 100% 100%;
  }
}

@media (max-width: 990px) {
  .homeBanner_Txt {
    text-align: left;
  }
  .homeBanner_Txt h2 {
    font-size: 24px;
    font-family: var(---HeadFont);
    color: var(---lightColor);
  }
}


@media (max-width: 576px) {
  .home_Banner1 {
    height: 50vh;
  }
  .home_Banner2 {
    height: 50vh;
  }
  .home_Banner3 {
    height: 50vh;
  }
}

/* ============ About Section ======= */
.aboutSection .col-md-6 {
  min-height: 450px;
}
.aboutPageImg {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 400px;
}

.aboutPageImg img {
  width: 100%;
  height: 100%;
  max-width: 93%;
  max-height: 450px;
  transform: translate(40px, 40px);
  border-top: 40px solid var(---lightColor);
  border-left: 40px solid var(---lightColor);
  border-radius: 10px;
}


.about_txt h2 {
  font-size: 28px;
  font-family: var(---HeadFont);
}

.aboutSection a {
  margin-top: 1.5em;
  padding: 0.65em 1.9em;
}


@media (max-width: 990px) {
  .aboutPageImg img {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 350px;
    transform: translate(25px, 25px);
    border-top: 30px solid var(---lightColor);
    border-left: 30px solid var(---lightColor);
    border-radius: 10px;
  }
  .aboutPageImg {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 300px;
  }
  .aboutPageText h2 {
    font-size: 2.5rem;
  }
}

@media (max-width: 767px) {
  .aboutPageSection .col-md-6 {
    min-height: 350px;
  }
}

@media (max-width: 500px) {
  .aboutPageImg img {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 300px;
    transform: translate(20px, 20px);
    border-top: 15px solid var(---lightColor);
    border-left: 15px solid var(---lightColor);
    border-radius: 10px;
  }
  .aboutPageText h2 {
    font-size: 2rem;
  }
}

@media (max-width: 575px) {
  .aboutPageImg img {
    width: 100%;
    height: 100%;
    max-width: 95%;
    max-height: 300px;
    transform: translate(20px, 20px);
    border-top: 15px solid var(---lightColor);
    border-left: 15px solid var(---lightColor);
    border-radius: 10px;
  }

  .aboutSection .col-md-6 {
    min-height: 360px;
  }
}
.aboutImg .home-about{
  position: relative;
  padding-left: 20px;
  padding-bottom: 82px;
}
.aboutImg .img1 {
  position: relative;
  display: inline-block;
  border-radius: 10px;
  margin-bottom: 0px;
  width: 100%;
  height: 400px;
}
.aboutImg .img1 img{
  width: 100%;
height: 100%;
}

.home-about .img2{
  position: absolute;
  right: 0px;
  bottom: 41px;
  margin-bottom: 0;
  border: 10px solid var(---lightColor);
  overflow: hidden;
  width: 200px;
  height: 225px;
}

.aboutImg .img2 img{
  width: 100%;
height: 100%;
}

.about-txt span{
  color: var(---primaryColor);
  letter-spacing: 2px;
}
.about-txt h2{
  font-family: var(---HeadFont);
}
.about-txt p{
  color: rgba(255, 255, 255, 0.5);
}


@media all and (max-width: 450px) {
  .aboutImg .img1{
    width: 100%;
    height: 100%;
  }
  .aboutImg .img2 {
    position: absolute;
    /* right: -12px; */
    bottom: 41px;
    margin-bottom: 0;
    border: 10px solid var(---lightColor);
    overflow: hidden;
    width: 150px;
  height: 175px;
  }
}

@media all and (max-width: 310px) {
  .aboutImg .img2 {
    display: none;
  }
}

/* ========== Room Section ============ */
.room-service{
  background-color:#1b1b1b;
}
.room-service .room-item {
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
}
.home-room-img{
  width: 100%;
  height: 100%;
}
.home-room-img img{
  width: 100%;
  height: 100%;
  max-height: 350px;
  border-radius: 5px;
}
.room-service .room-item:hover img {
  -webkit-filter: none;
  filter: none;
  -webkit-transform: scale(1.09, 1.09);
  transform: scale(1.09, 1.09);
  -webkit-filter: brightness(70%);
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease;
}
.room-service .room-item:hover .con {
  bottom: 0;
}
.room-service .room-item img {
  -webkit-transition: all .5s;
  transition: all .5s;
}
.room-service .room-item .category {
position: absolute;
  top: 20px;
  right: 20px;
  z-index: 3;
  writing-mode: vertical-rl;
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  text-orientation: mixed;
  text-transform: uppercase;
  color: var(---lightColor);
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 3px;
  background: transparent;
  border: 1px solid rgba(255,255,255,0.5);
  font-family: 'Barlow Condensed', sans-serif;
  padding: 10px 3px;
  line-height: 1.5em;
}
.room-service .room-item .category a {
  color: var(---lightColor);
}
.room-service .room-item .category:hover {
  color: var(---lightColor);
}


.category button{
  background-color: transparent;
  color: var(---lightColor);
  border: none;
}
.room-service .room-item .con {
  padding: 20px;
  position: absolute;
  bottom: -60px;
  left: 0;
  width: 100%;
  -webkit-transition: all .3s;
  transition: all .3s;
  text-align: left;
  z-index: 20;
  height: auto;
  box-sizing: border-box;
  background: -moz-linear-gradient(top, transparent 0, rgba(0, 0, 0, .1) 2%, rgba(0, 0, 0, .75) 90%);
  background: -webkit-linear-gradient(top, transparent 0, rgba(0, 0, 0, .01) 2%, rgba(0, 0, 0, .75) 90%);
  background: linear-gradient(to bottom, transparent 0, rgba(0, 0, 0, .01) 2%, rgba(0, 0, 0, .75) 90%);
}
.room-service .room-item .con h6,
.room-service .room-item .con h6 a {
  position: relative;
  color: var(---lightColor);
  font-size: 15px;
  font-family: 'Barlow Condensed', sans-serif;
  display: inline;
  letter-spacing: 3px;
  text-transform: uppercase;
}
.room-service .room-item .con h6 i {
  font-size: 6px;
  margin-right: 2px;
}
.room-service .room-item .con h5,
.room-service .room-item .con h5 a {
  position: relative;
  color: var(---lightColor);
  font-size: 27px;
  font-weight: 400;
  margin-top: 5px;
  margin-bottom: 5px;
}
.room-service .room-item .con p {
  color: var(---lightColor);
  font-size: 20px;
}
.room-service .room-item .con i {
  color: var(---lightColor);
  font-size: 18px;
}
.room-service .room-item .con i:hover {
  color: var(---lightColor);
}
.room-service .room-item .con .butn-light {
  margin-left: 0;
  border-radius: 30px;
  border-color: rgba(255,255,255,0.5);
  padding: 11px 30px;
}
.room-service .room-item .con .butn-light:hover {
  border-color: var(---primaryColor);
}
/* line animation */
.room-service .room-item .line {
  text-align: center;
  height: 1px;
  width: 60px;
  background-color: rgba(255, 255, 255, 0.2);
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  margin: auto 0 30px;
}
.room-service .room-item:hover .line {
  width: 100%;
  transition-property: all;
  transition-duration: 0.8s;
  transition-timing-function: ease-in-out;
}
/* facilities */
.room-service .facilities ul {
margin: 0;
padding: 0;
list-style: none;
}
.room-service .facilities ul li {
color: var(---lightColor);
font-size: 10px;
text-transform: uppercase;
  letter-spacing: 3px;
line-height: 1.2em;
padding-bottom: 20px;
  float: left;
}
.room-service .facilities ul li i {
margin-right: 10px;
}
/* permalink */
.room-service .room-item .con .permalink {
  font-family: 'Barlow Condensed', sans-serif;
  font-size: 15px;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: var(---lightColor);
  height: 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  display: inline;
}
.room-service .room-item .con .permalink a {
  color: var(---lightColor);
  text-align: right;
}
.room-service .room-item .con .permalink i {
 font-size: 10px;
  text-align: right;
}
.room-service .room-item .con .permalink a:hover,
.room-service .room-item .con .permalink i:hover {
color:var(---primaryColor);
}


/* ========= Resort Section ============ */
.wedding-lawn {
  background-color:#1b1b1b;
}
.Resort_txt h6{
  color: var(---primaryColor);
}
.resortHead h2 {
  font-size: 28px;
  font-family: var(---HeadFont);
}
.wedding-img{
  width: 100%;
  height: 100%;
}
.wedding-img img{
  width: 100%;
  height: 100%;
}

/* ========= AminitieSection ============ */
/* .aminitiesSection {
  background-color:#1b1b1b;
} */

.aminitiesSection .row{
  display: flex;
  justify-content: center;
  align-items: center;
}

.aminitiCard {
  display: flex;
  align-items:center;
  justify-content:center;
  flex-direction: column;
  /* box-shadow: 0 0 5px gray; */
  padding: 20px 30px;
  border-radius: 5px;
}
.aminiti-Icon{
  width: 100%;
  height: 100%;
  max-width: 70px;
  max-height: 70px;
  border: 1px solid var(---primaryColor);
  padding: 15px;
  border-radius: 50%;
}
.aminiti-Icon img{
  width: 100%;
  height: 100%;
}

.aminitiCard h3 {
  color:var(---lightColor);
  font-weight: normal;
  text-transform: capitalize;
  text-align: center;
  margin: 10px 0;
  font-family: var(---HeadFont);
  font-size: 18px;
}

@media (max-width: 500px) {
  .aminitiCard h3 {
    font-size: 14px;
  }
}

/* =============== Near by Attraction ============== */
.nearbyAttrection .attrectionCard {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0 2px 5px #808080;
  transition: 0.5s ease-in-out;
  overflow: hidden;
  max-height: 250px;
}

.attrectionImg {
  width: 100%;
  height: 100%;
}

.attrectionImg img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  min-width: 350px;
  min-height: 350px;
}

.attrectionImg h3 {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: fit-content;
  padding: 10px 15px;
  background-color: rgb(0, 0, 0, 0.5);
  margin: 0;
  text-align: center;
  transition: 0.5s ease-in-out;
  color: var(---lightColor);
  font-size: 20px;
  font-family: var(---HeadFont);
}

.attractionBody {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: rgb(0, 0, 0, 0.5);
  bottom: -100%;
  opacity: 0;
  transition: 0.5s ease-in-out;
  padding: 10px;
}

.attractionBody h3 {
  text-align: center;
  font-weight: 500;
  color: var(---lightColor);
  font-size: 20px;
  font-family: var(---HeadFont);
}

.attractionBody p {
  text-align: center;
  color: #eee;
}

.attrectionCard:hover .attrectionImg h3 {
  visibility: hidden;
  opacity: 0;
  transition: 0.5s ease-in-out;
}

.attrectionCard:hover .attractionBody {
  bottom: 0;
  transition: 0.5s ease-in-out;
  opacity: 1;
}

@media (max-width: 992px) {
  .attrectionCard {
    max-height: 220px;
    margin: 8px 8px 20px 8px;
  }
}

@media (max-width: 500px) {
  .attrectionCard {
    max-height: 220px;
    margin: 8px 8px 20px 8px;
  }

  .attractionBody p {
    text-align: justify;
    color: #eee;
  }
}
/* ========== Promotional Video ======= */
.video-wrapper {
  background-image: linear-gradient(
    120deg,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0.5) 100%
  ),
  url("../img/gallery/slidernew1.jpg");
  height: 80vh;
  background-size: 100% 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.video-wrapper .vid {
  position: relative;
  z-index: 8;
}
.video-wrapper .vid-butn{
  display: flex;
    justify-content: center;
    align-items: center;
}
.video-wrapper .vid .vid-butn:hover .icon {
  color: var(---lightColor);
}
.video-wrapper .vid .vid-butn:hover .icon:before {
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
}
.video-wrapper .vid .vid-butn .icon {
  color: #aa8453;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  line-height: 100px;
  text-align: center;
  font-size: 40px;
  position: relative;
  -webkit-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s;
  display: flex;
    justify-content: center;
    align-items: center;
}
.video-wrapper .vid .vid-butn .icon:after {
  content: '';
  position: absolute;
  top: 5px;
  bottom: 5px;
  right: 5px;
  left: 5px;
  border: 1px solid #eee;
  border-radius: 50%;
  z-index: -1;
}
.video-wrapper .vid .vid-butn .icon:before {
  content: '';
  position: absolute;
  top: 5px;
  bottom: 5px;
  right: 5px;
  left: 5px;
  background: transparent;
  border-radius: 50%;
  z-index: -1;
  -webkit-transition: all 0.5s cubic-bezier(1, 0, 0, 1);
  -o-transition: all 0.5s cubic-bezier(1, 0, 0, 1);
  transition: all 0.5s cubic-bezier(1, 0, 0, 1);
}


/* video gallery */
.video-gallery-button {
  position: relative;
  margin: auto;
  -webkit-transform: scale(1.3);
  -ms-transform: scale(1.3);
  transform: scale(1.3);
  z-index: 4;
  margin-left: 15px;
  float: right;
}
.video-gallery-polygon {
  z-index: 2;
  padding-left: 5px;
  display: inline-block;
  position: absolute;
  bottom: 15px;
  right: 15px;
  background: transparent;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.5);
  color: #fff;
  padding: 7px 6px 7px 8px;
  line-height: 0;
}
.video-gallery-polygon:hover {
  background: #fff;
  border: 1px solid #fff;
  color: #aa8453;
}

.video-wrapper .headTitle h2{
  color: var(---lightColor);
}
.video-wrapper .headTitle p{
  color: var(---lightColor);
}

/* ============ Testimonal Section ========= */
.testimonalSection {
  background-color:#1b1b1b;
}
.testimonalSection .item .test-cont {
  background: var(---lightColor);
  padding: 2em;
  border-radius: 8px;
  position: relative;
  display: block;
}

.testimonalSection .item .test-cont:after {
  width: 0;
  height: 0;
  border-left: 1px solid transparent;
  border-right: 20px solid transparent;
  border-top: 26px solid var(---lightColor);
  position: absolute;
  content: "";
  bottom: -26px;
}

.testimonalSection .item .test-cont p {
  color: #111;
  font-family: var(---SubHeading);
}

.testimonalSection .item .test-bot {
  padding: 32px 0 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.client_img {
  margin-right: 15px;
  width: 70px;
  height: 70px;
  border-radius: 50%;
}

.client_img img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.testimonalSection .item .test-bot .client_name {
  float: left;
  margin: 1em 0 0;
}

.testimonalSection .item .test-bot .client_name h5 {
  color:var(---lightColor);
  padding: 0;
  margin: 0;
  font-size: 18px;
  font-family: var(---HeadFont);
}

.testimonalSection .item .test-bot .client_name h5 span {
  color:var(---primaryColor);
  font-family: var(---HeadFont);
  font-size: 18px;
}

.testimonalSection .item .test-bot .client_name ul {
  margin: 0px;
  padding: 0px;
}
.testimonalSection .item .test-bot .client_name ul li {
  display: inline-block;
  margin: 2px -1px 0 1px;
}

.testimonalSection .item .test-bot .client_name ul li i {
  color: var(---primaryColor);
  font-size: 1em;
  margin: 0 8px 0 0;
}

.testimonial-sec-p {
  padding: 6em 1em 1em;
  border-bottom: 1px solid #e4e4e4;
}

.testimonial-p .media-body h3 {
  font: 2em/1em "Playball", sans-serif;
  letter-spacing: 0.3px;
  color: #242424;
  margin: 0 0 0.5em;
  padding: 0;
}

.testimonial-p .media-body p {
  font: 400 1.0715em/1.8em "Open Sans", sans-serif;
  letter-spacing: 0.3px;
  color: #898989;
  margin-bottom: 1em;
}

.testimonial-p .media-body a {
  font: 600 1.0715em/1em "Open Sans", sans-serif;
  letter-spacing: 0.3px;
  color: #1f4d84;
  font-style: italic;
  text-align: left;
  display: block;
}

.testimonial-p .media-body a span {
  color: #3d3d3d;
  font: 400 0.9em/1em "Open Sans", sans-serif;
}

.testimonial-p .media-body h6 {
  text-align: right;
  display: block;
}

.testimonial-p .media-left {
  padding-right: 60px;
}

.testimonial-p .media-left a img {
  border-left: 4px solid var(---primaryColor);
  border-right: 1px solid #e1e1e1;
  border-top: 2px solid var(---primaryColor);
  border-bottom: 1px solid #e1e1e1;
}

.testimonial-p .m-right {
  float: right;
  padding-right: 0;
  padding-left: 60px;
}

.testimonial-p .m-left {
  float: left;
  width: 82%;
}

.testimonial-p .l-sign {
  float: left;
}

.common-pad-test {
  padding-top: 0em;
  padding-bottom: 6.4em;
}

@media (max-width: 479px) {
  .testimonial-p .media-left {
    display: block;
    text-align: center;
    padding-right: 0;
  }

  .testimonial-p .media-body h3 {
    padding: 1em 0 0;
  }
}

/* =============== Near by Attraction ============== */
.gallerCard {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0 5px 7px #808080;
  transition: 0.5s ease-in-out;
  overflow: hidden;
  max-height: 250px;
}

.gallerImg {
  width: 100%;
  height: 100%;
}

.gallerImg img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  min-width: 350px;
  min-height: 350px;
}

.gallerImg h3 {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: fit-content;
  padding: 10px 15px;
  background-color: rgb(0, 0, 0, 0.5);
  margin: 0;
  text-align: center;
  transition: 0.5s ease-in-out;
  color: var(---lightColor);
  font-size: 26px;
  font-family: var(---header);
  letter-spacing: 3px;
}

.gallerBody {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: rgb(0, 0, 0, 0.5);
  bottom: -100%;
  opacity: 0;
  transition: 0.5s ease-in-out;
  padding: 10px;
}

.gallerBody h3 {
  text-align: center;
  font-weight: 500;
  color: var(---lightColor);
  font-size: 26px;
}

.gallerBody p {
  text-align: center;
  color: #eee;
}

.gallerCard:hover .gallerImg h3 {
  visibility: hidden;
  opacity: 0;
  transition: 0.5s ease-in-out;
}

.gallerCard:hover .gallerBody {
  bottom: 0;
  transition: 0.5s ease-in-out;
  opacity: 1;
}

@media (max-width: 992px) {
  .gallerCard {
    max-height: 220px;
    margin: 8px 8px 20px 8px;
  }
}

@media (max-width: 500px) {
  .gallerCard {
    max-height: 220px;
    margin: 8px 8px 20px 8px;
  }

  .gallerBody p {
    text-align: justify;
    color: #eee;
  }
}

/* ============== Form section ====== */
.booking-section {
  position: relative;
  padding: 110px 0 107px;
  margin-top: 100px;
}
.booking-section:before {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  max-width: 1200px;
  height: 100%;
  background-image: url(../img/bg-booking.jpg);
  background-repeat: no-repeat;
}

.booking-column .booking-left {
  padding: 30px;
  position: relative;
  z-index: 9;
  margin-top: -170px;
  border-top: 10px solid var(---primaryColor);
}

.booking-column .booking-left:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #f8f5f0;
  z-index: 9;
  height: 100%;
}
.booking-column .booking-left .booking-left-Txt {
  margin-bottom: 54px;
  position: relative;
  z-index: 9;
}

.booking-left-Txt span{
  color: var(---primaryColor);
  font-family: var(---HeadFont);
}

.booking-left-Txt h2 {
  color: var(---darkColor);
  font-family: var(---HeadFont);
}

.booking-column .booking-left .booking-form {
  position: relative;
  z-index: 9;
}
.booking-column .booking-left .booking-form label {
  color: #111;
  font-size: 14px;
  font-family: var(---HeadFont);
  width: 100%;
}
.booking-column .booking-left .booking-form .form-control {
  border-radius: 0px;
}
.booking-column .booking-left .booking-form .error-text {
  font-family: var(---HeadFont);
  font-size: 12px;
}
.booking-column .booking-left .booking-form button{
  font-size: 20px;
  color: var(---lightColor);
  background-color: var(---primaryColor);
  padding: 5px 12px;
  border: none;
  color: white;
  padding: 7px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-family: var(---HeadFont);
}


.booking-content .booking-right {
  padding-left: 10px;
  position: relative;
}
.booking-content .booking-right .bookingsec-title span {
  color: var(---lightColor);
  font-size: 16px;
  font-family: var(---HeadFont);
  padding-bottom: 10px;
  letter-spacing: 7.4px;
}
.booking-content .booking-right .bookingsec-title h2 {
  margin-bottom: 30px;
  color:white;
  font-size: 30px;
  font-family: var(---HeadFont);
}

.booking-content .booking-right h4{
  color:#eee;
  font-family: var(---SubHeading);
}

.booking-content .booking-right p {
  color: var(---lightColor);
  font-size: 16px;
  font-family: var(---SubHeading);
}

.booking-content .booking-right p a{
  color: var(---lightColor);
  font-size: 16px;
  font-family: var(---SubHeading);
}

@media (max-width: 992px) {
  .booking-section {
    padding: 30px 0px;
    margin-top: 50px;
  }
  .booking-column .booking-left {
    margin-top: 0px;
  }
  .contactsec-title {
    position: relative;
    margin: 10px 0px 10px 0px;
  }
  .booking-content .booking-right .bookingsec-title {
   margin-top:30px;
  }
}
