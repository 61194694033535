.About-banner {
  background-image: linear-gradient(
      120deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0.5) 100%
    ),
    url("../img/gallery/gallery4.jpg");
  height: 70vh;
  background-size:cover;
  background-position:top;
  position: relative;
  display: flex;
  justify-content: center;
  object-fit: contain;
  align-items: center;
}
.AboutBanner-Txt {
  margin-top: 100px;
}

.AboutBanner-Txt h2 {
  font-size: 50px;
  font-family: var(---HeadFont);
  color: var(---lightColor);
  text-align: center;
}
.AboutBanner-Txt p {
  text-align: center;
  color: var(---lightColor);
  font-size: 24px;
}

@media (max-width: 990px) {
  .AboutBanner_Txt {
    margin-top: 40px;
  }
  .AboutBanner-Txt h2 {
    font-size: 24px;
    font-family: var(---HeadFont);
    color: var(---lightColor);
    text-align: center;
  }
  .AboutBanner-Txt p {
    color: var(---lightColor);
    padding: 0px 10px 0px 10px;
    font-size: 16px;
  }
}
@media (max-width: 768px) {
  .About-banner {
    height: 50vh;
  }
}

/* ========= About Page Section ===== */
.AboutImg{
  width: 100%;
  height: 100%;
  max-height: 300px;
  border-radius: 5px;
}
.AboutImg img{
  width: 100%;
  height: 100%;
  border-radius: 5px;
  box-shadow: 0 0 7px gray;
}
.AboutTxt{
  margin-top: 30px;
}

.AboutTxt1{
  display: none;
}

@media all and (max-width: 990px) {
  .sec-title{
    margin-top: 20px;
  }
  .AboutTxt{
    display: none;
  }
  .AboutTxt1{
    display: block;
  }
}

/* ========== Counter Section =========== */
.counter_txt h2 {
  color: var(---lightColor);
  font-family: var(---HeadFont);
  line-height: 35px;
}

.counter_txt p {
  color: var(---lightColor);
  font-family: var(---SubHeading);
}
.counter-section {
  background-image: linear-gradient(
      120deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0.5) 100%
    ),
    url("../img/baanner/2.jpg");
  height: 65vh;
  background-size: 100% 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-attachment: fixed;
}
.counter.left_icn {
  padding-left: 73px;
  position: relative;
}
.counter {
  color: var(---lightColor);
  font-family: var(---HeadFont);
  min-height: 60px;
  position: relative;
}
.counter.left_icn i {
  bottom: auto;
  color: var(---lightColor);
  left: 0;
  position: absolute;
  top: 3px;
  background-color: var(---primaryColor);
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.text-white .counter i {
  color: var(---primaryColor);
}
.counter i,
.counter i:before {
  font-size: 35px;
}
.counter i {
  display: inline-block;
  line-height: 64px;
}
.counter span {
  display: block;
  font-size: 44px;
  font-weight: 500;
  line-height: 40px;
  margin: 10px 0 5px;
}
.counter label {
  color: var(---lightColor);
  font-size: 15px;
  font-weight: 500;
  margin: 10px 0 0;
  position: relative;
  text-transform: uppercase;
}

.count-btn {
  border: none;
  outline: none;
  border: 1px solid var(---lightColor);
  font-size: 12px;
  line-height: 24px;
  color: var(---lightColor);
  text-transform: uppercase;
  font-family: var(---HeadFont);
  display: inline-block;
  border-radius: 3px;
  transition: all 0.5s ease;
  padding: 7px 15px;
}

.count-btn:hover {
  background: var(---primaryColor);
  color: var(---lightColor);
}

@media screen and (max-width: 1200px) {
  .counter span {
    font-size: 40px;
    line-height: 40px;
  }
}
@media screen and (max-width: 992px) {
  .counter-section {
    height: auto;
  }
  .counter.left_icn {
    padding-left: 60px;
    position: relative;
  }
  .counter {
    color: var(---lightColor);
    font-family: var(---HeadFont);
    min-height: 40px;
    position: relative;
  }
  .counter.left_icn i {
    bottom: auto;
    left: 0;
    line-height: 60px;
    position: absolute;
    top: 5px;
  }
  .counter i,
  .counter i:before {
    font-size: 30px;
  }
  .counter i {
    display: inline-block;
    line-height: 42px;
  }
  .counter span {
    display: block;
    font-size: 28px;
    font-weight: 500;
    line-height: 28px;
    margin: 10px 0 5px 2px;
  }
  .counter label {
    color: var(---lightColor);
    font-size: 15px;
    font-weight: 500;
    margin: 0px 0 0 10px;
    position: relative;
    text-transform: uppercase;
  }
}
@media screen and (max-width: 502px) {
  .counter label {
    color: var(---lightColor);
    font-size: 14px;
    font-weight: 500;
    margin: 0 0 0 5px;
    position: relative;
    text-transform: uppercase;
  }
}

/* ============ testimonal ========= */
.tst-img {
  width: 75px;
  height: 75px;
}
.tst-img img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}


